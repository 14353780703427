import React from "react";
import Navbar from "components/Navbar/navbar";
import Footer from "components/Footer/footer";
import DarkTheme from "layouts/Dark";
import FullTestimonialsElf from "components/Elf/Full-testimonialsElf/full-testimonialsElf";
import AboutUs from "data/elf/about-us.json";
const AboutUsElf = () => {
  const fixedSlider = React.useRef(null);
  const MainContent = React.useRef(null);
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    setTimeout(() => {
      if (fixedSlider.current) {
        var slidHeight = fixedSlider.current.offsetHeight;
      }
      if (MainContent.current) {
        MainContent.current.style.marginTop = slidHeight + "px";
      }
    }, 1000);

    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [fixedSlider, MainContent, navbarRef]);

  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      {/* <IntroWithSlider sliderRef={fixedSlider} /> */}
      <div ref={MainContent} className="main-content">
        {/* <Services4 serviceMB50 />
        <AboutUs4 />
        <Numbers /> */}
        {/* <WorksStyle3 /> */}
        <FullTestimonialsElf contentdata={AboutUs} />
        {/* <Blogs2 />
        <CallToAction img="/img/patrn1.png" /> */}
        <Footer />
      </div>
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Elfonze Technologies - About Us</title>
      <meta key="description" name="description" 
      content="Elfonze Technologies are Hands-on entrepreneurs driving technology to bridge vacuum around quality in IT Service, Consulting & Corporate Trainings along with" />
    </>
  )
}

export default AboutUsElf;
